import { DataKiSyokenResponse } from './types';

export const dataKiSyokenHiddenByField = (
  dataKiSyoken: DataKiSyokenResponse,
  mkshallsFilter: string[] | undefined,
  fields: string[] | undefined
): DataKiSyokenResponse => {
  if (fields == null) {
    return dataKiSyoken;
  }

  const hiddenIndexes = fields.map((field) => {
    return dataKiSyoken.data.columns.findIndex(
      (column) => column.code === field
    );
  });

  // 2段組を考慮した数値フィルタ用のColumnリストを作成する
  const expandedColumnsForFilter = dataKiSyoken.data.columns.flatMap(
    (column) => {
      if (column.isSyoken === true) {
        const additionalData = dataKiSyoken.data.hallsOfMarketArea.map(
          (hall) => {
            return {
              ...column,
              code: column.code,
              name: `${column.name}_${hall.name}`,
            };
          }
        );

        // 先頭に追加するデータ
        const initialData = {
          ...column,
          code: column.code,
          name: '商圏全体',
        };

        return mkshallsFilter?.includes('mksAll')
          ? [initialData, ...additionalData]
          : additionalData;
      } else {
        return {
          ...column,
        };
      }
    }
  );

  // 2段組を考慮したRowsのhiddenIndexesを取得
  const hiddenRowsIndexes = expandedColumnsForFilter.reduce<number[]>(
    (indexList, column, index) => {
      if (fields.some((field) => column.code === field)) {
        indexList.push(index);
      }
      return indexList;
    },
    []
  );

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      columns: dataKiSyoken.data.columns.filter(
        (_, i) => !hiddenIndexes.includes(i)
      ),
      rows: dataKiSyoken.data.rows.map((row) => {
        return {
          ...row,
          data: row.data.filter((_, i) => !hiddenRowsIndexes.includes(i)),
        };
      }),
    },
  };
};
