import { ConvertRowsToFilteredRows } from '../../components/organisms/TableHeaderSubMenu/ConvertRowsToFilteredRows';

import { DataKiSyokenResponse } from './types';

export const dataKiSyokenFilteredByNumber = (
  dataKiSyoken: DataKiSyokenResponse,
  numberFilter:
    | {
        [field: string]: {
          minimumNumber: number | undefined;
          maximumNumber: number | undefined;
        };
      }
    | undefined
): DataKiSyokenResponse => {
  if (numberFilter == null) {
    return dataKiSyoken;
  }

  // 2段組を考慮した数値フィルタ用のColumnリストを作成する
  const expandedColumnsForFilter = dataKiSyoken.data.columns.flatMap(
    (column) => {
      if (column.isSyoken === true) {
        const additionalData = dataKiSyoken.data.hallsOfMarketArea.map(
          (hall) => {
            return {
              ...column,
              code: `${column.code}_${hall.code}`, // 他の商圏店舗列と判別ができるように項目のcodeに店舗コードを付与する
              name: hall.name,
            };
          }
        );

        // 先頭に追加するデータ
        const initialData = {
          ...column,
          code: column.code,
          name: '商圏全体',
        };

        return [initialData, ...additionalData];
      } else {
        return {
          ...column,
        };
      }
    }
  );

  const { filteredRows: rows } = ConvertRowsToFilteredRows(
    numberFilter,
    expandedColumnsForFilter,
    dataKiSyoken.data.rows
  );

  return {
    ...dataKiSyoken,
    data: {
      ...dataKiSyoken.data,
      rows,
    },
  };
};
